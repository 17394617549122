<template>
	<div class="select" :class="__class" :disabled="disabled ? '' : undefined" ref="select">
		<input class="select-input" type="hidden" :name="name" :value="_value" disabled readonly>
		
		<div class="select-content">
			<div class="select-primary-content" @click.prevent="toggle">
				<slot name="primary-left-content"></slot>
				
				<p class="text" :class="{'select-placeholder': __option === undefined}">{{ __option === undefined ? placeholder : __option.text }}</p>
				
				<slot name="primary-right-content"></slot>
				
				<svg class="select-indicator icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
					<path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
				</svg>
			</div>
			<div class="select-loading-content">
				<slot name="loading-content"></slot>
				
				<svg v-if="!$slots['loading-content']" class="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
					<path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
					<path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
				</svg>
			</div>
		</div>
		<div class="select-options">
			<div class="select-options-search">
				<vue-input :value="_search.query" placeholder="Введите для поиска..." @value="(value) => _search.query = value" />
			</div>
			
			<p v-if="_search.query.length > 0 && __options.length === 0" class="select-options-search-empty">По Вашему запросу ничего не найдено!</p>
			
			<div v-if="__options.length > 0" class="select-options-list">
				<div v-for="{ value, text, description }, i in __options" :key="i" class="select-option" :class="{'select-option-active': this._value === value}" @click.prevent="!__readonly && select(value)">
					<div class="select-option-container">
						<p class="select-option-text text">{{ text }}</p>
						
						<p v-if="typeof description === 'string' && description.length !== 0" class="select-option-description text">{{ description }}</p>
					</div>
					
					<svg class="select-option-indicator icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
						<path d="M 14.542 3.441 C 15.127 4.026 15.127 4.975 14.542 5.561 L 7.542 12.561 C 6.956 13.145 6.008 13.145 5.422 12.561 L 1.422 8.561 C 0.635 7.716 1.057 6.336 2.182 6.076 C 2.668 5.964 3.177 6.101 3.542 6.441 L 6.482 9.38 L 12.422 3.44 C 13.008 2.855 13.956 2.855 14.542 3.44" />
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import VueInput from '@/components/VueInput';
	
	export default {
		name: 'VueSelect',
		
		emits: [
			'value',
			'focus',
			'blur'
		],
		
		components: {VueInput},
		
		props: {
			options: {type: [Array], required: false, default: []},
			
			value: {type: [String, Number], required: false},
			placeholder: {type: String, required: false},
			
			name: {type: String, required: false},
			
			required: {type: [Boolean, String, Number], required: false},
			
			readonly: {type: [Boolean, String, Number], required: false},
			disabled: {type: [Boolean, String, Number], required: false},
			
			loading: {type: [Boolean, String, Number], required: false}
		},
		
		data() {
			return {
				_value: this.value,
				
				_state: {
					focused: false
				},
				
				_search: {
					query: ''
				}
			};
		},
		
		computed: {
			__class() {
				return {
					'select-readonly': this.__readonly,
					
					'select-loading': this.loading,
					
					'select-focused': this._state.focused
				};
			},
			
			__options() {
				return this.options.filter(({ value, text, description }) => {
					if(value === undefined || !['string', 'number'].includes(typeof value)) return false;
					
					if(text === undefined || !['string', 'number'].includes(typeof text)) return false;
					
					if(description !== undefined && !['string', 'number'].includes(typeof description)) return false;
					
					if(this._search.query.length > 0) return text.toLowerCase().indexOf(this._search.query.toLowerCase()) !== -1;
					
					return true;
				});
			},
			
			__readonly() {
				return this.readonly || this.disabled || this.loading;
			},
			
			__option() {
				return this.__options.find(({ value }) => value === this._value);
			}
		},
		
		methods: {
			focus() {
				this._state = {
					...this._state,
					
					focused: true
				};
			},
			
			blur() {
				this._state = {
					...this._state,
					
					focused: false
				};
			},
			
			toggle() {
				this._state = {
					...this._state,
					
					focused: !this._state.focused
				};
			},
			
			select(value) {
				const option = this.__options.find((item) => item.value === value);
				
				this._value = option === undefined ? undefined : option.value;
				
				this.blur();
			},
			
			onMouseDown(event) {
				if(event.target.closest('.select') !== this.$refs.select) this.blur();
			},
			
			onTouchStart(event) {
				if(event.target.closest('.select') !== this.$refs.select) this.blur();
			},
			
			onClick(event) {
				if(event.target.closest('.select') !== this.$refs.select) this.blur();
			}
		},
		
		watch: {
			value(value) {
				this._value = value;
			},
			
			_value(value) {
				if(this.__readonly) {
					this._value = this.value;
					
					return;
				}
				
				this.$emit('value', value);
			},
			
			'_state.focused'(state) {
				if(state) this._search.query = '';
				
				this.$emit(state ? 'focus' : 'blur');
			}
		},
		
		mounted() {
			document.addEventListener('mousedown', this.onMouseDown, {passive: true});
			document.addEventListener('touchstart', this.onTouchStart, {passive: true});
			document.addEventListener('click', this.onClick, {passive: true});
		},
		
		beforeUnmount() {
			document.removeEventListener('mousedown', this.onMouseDown);
			document.removeEventListener('touchstart', this.onTouchStart);
			document.removeEventListener('click', this.onClick);
		}
	}
</script>

<style>
	.input-group {
		display: flex;
		
		column-gap: 10px;
	}
	
	.input-group > .select {
		flex-grow: 1;
	}
	
	.select {
		height: 34px;
		
		width: 100%;
		
		position: relative;
		
		padding: 0 7.5px;
		
		background-color: var(--secondary-background-color);
		
		border: 1px solid var(--secondary-border-color);
		
		border-radius: 6px;
		
		transition: opacity, background-color, border-color, color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.select.select-large {
		height: 50px;
		
		padding: 0 15px;
	}
	
	.select[disabled] {
		opacity: .6;
	}
	
	.select[disabled],
	.select.select-loading {
		pointer-events: none;
	}
	
	.select.select-focused,
	.select.select-invalid {
		border-color: var(--accent-color);
	}
	
	.select.select-invalid::before {
		background-color: var(--danger-color);
	}
	
	.select > .select-content {
		width: 100%;
		height: 100%;
	}
	
	.select > .select-content .icon {
		width: 16px !important;
		height: 16px !important;
	}
	
	.select > .select-content > .select-primary-content,
	.select > .select-content > .select-loading-content {
		display: flex;
		
		align-items: center;
		
		width: 100%;
		height: 100%;
		
		column-gap: 10px;
		
		opacity: 1;
		
		transition: opacity;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.select.select-large > .select-content > .select-primary-content,
	.select.select-large > .select-content > .select-loading-content {
		column-gap: 15px;
	}
	
	.select > .select-content > .select-primary-content {
		opacity: 1;
		
		cursor: pointer;
	}
	
	.select.select-loading > .select-content > .select-primary-content {
		opacity: 0;
	}
	
	.select > .select-content > .select-loading-content {
		position: absolute;
		
		top: 0;
		left: 0;
		
		pointer-events: none;
		
		justify-content: center;
		
		opacity: 0;
	}
	
	.select.select-loading > .select-content > .select-loading-content {
		opacity: 1;
	}
	
	.select > .select-content > .select-primary-content > .icon,
	.select > .select-content > .select-loading-content > .icon {
		flex-shrink: 0;
	}
	
	.select > .select-content > .select-loading-content > .icon {
		animation: select-loading 1s linear infinite;
	}
	
	.select > .select-content > .select-primary-content > .text.wide,
	.select > .select-loading-content > .text.wide {
		flex-grow: 1;
	}
	
	.select > .select-content > .select-primary-content > .text {
		flex-grow: 1;
		
		width: 100%;
		
		font-size: .8rem;
		
		user-select: none;
		
		opacity: 1;
		
		transition: opacity;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.select.select-large > .select-content > .select-primary-content > .text {
		font-size: 1rem;
	}
	
	.select > .select-content > .select-primary-content > .select-placeholder {
		opacity: .6;
	}
	
	.select > .select-content > .select-primary-content > .select-indicator {
		transform: matrix(1, 0, 0, 1, 0, 0);
		
		transition: transform;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.select.select-focused > .select-content > .select-primary-content > .select-indicator {
		transform: matrix(1, 0, 0, -1, 0, 0);
	}
	
	.select > .select-options {
		position: absolute;
		
		top: calc(100% + 11px);
		left: -1px;
		
		width: calc(100% + 2px);
		
		max-height: 250px;
		
		background-color: var(--secondary-background-color);
		
		border: 1px solid var(--accent-color);
		
		border-radius: 6px;
		
		display: flex;
		
		flex-direction: column;
		
		opacity: 0;
		
		visibility: hidden;
		
		pointer-events: none;
		
		z-index: 1;
		
		transform: translateY(-10px);
		
		transition: transform, opacity, visibility, background-color, border-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.select.select-focused > .select-options {
		opacity: 1;
		
		visibility: visible;
		
		pointer-events: auto;
		
		transform: translateY(0);
	}
	
	.select > .select-options > .select-options-search {
		padding: 10px;
		
		border-bottom: 1px solid var(--accent-color);
		
		flex-shrink: 0;
	}
	
	.select > .select-options > .select-options-search-empty {
		padding: 15px;
		
		font-size: .85rem;
		font-weight: bold;
		
		text-align: center;
	}
	
	.select > .select-options > .select-options-list {
		overflow: hidden auto;
		
		scrollbar-width: thin;
		scrollbar-color: var(--accent-color) transparent;
		
		height: 100%;
		
		display: flex;
		
		flex-wrap: wrap;
		
		padding: 10px;
	}
	
	.select > .select-options > .select-options-list > .select-option {
		padding: 10px;
		
		display: flex;
		
		align-items: center;
		justify-content: center;
		
		column-gap: 10px;
		
		cursor: pointer;
		
		user-select: none;
		
		border-radius: 4px;
		
		flex-grow: 1;
		
		text-align: center;
		
		transition: background-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.select > .select-options > .select-options-list > .select-option.select-option-active,
	.select > .select-options > .select-options-list > .select-option:hover {
		background-color: var(--tertiary-background-color);
	}
	
	.select > .select-options > .select-options-list > .select-option .icon {
		width: 16px !important;
		height: 16px !important;
	}
	
	.select > .select-options > .select-options-list > .select-option > .select-option-container {
		flex-grow: 1;
		
		display: flex;
		
		flex-direction: column;
		
		row-gap: 2.5px;
	}
	
	.select > .select-options > .select-options-list > .select-option > .select-option-container > .text {
		padding: 0 5px;
	}
	
	.select > .select-options > .select-options-list > .select-option > .select-option-container > .select-option-text {
		font-size: .85rem;
		font-weight: bold;
	}
	
	.select > .select-options > .select-options-list > .select-option > .select-option-container > .select-option-description {
		font-size: .8rem;
		
		color: var(--secondary-text-color);
		
		transition: color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.select > .select-options > .select-options-list > .select-option > .icon {
		flex-shrink: 0;
	}
	
	.select > .select-options > .select-options-list > .select-option > .select-option-indicator {
		color: var(--accent-color);
		
		display: none;
		
		transition: color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.select > .select-options > .select-options-list > .select-option.select-option-active > .select-option-indicator {
		display: block;
	}
	
	@keyframes select-loading {
		from {transform: rotate(0deg)}
		to {transform: rotate(360deg)}
	}
</style>
<template>
	<div class="root" :class="{'light-scheme': $store.state.scheme === 'light', 'dark-scheme': $store.state.scheme === 'dark'}">
		<div class="app">
			<header class="header">
				<div class="wrapper">
					<menu class="menu" :class="{active: menu.active}" ref="menu">
						<div class="menu-toggle" @click.prevent="menu.active = !menu.active">
							<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
								<path d="M 0 13.818 C 0 13.417 0.326 13.091 0.727 13.091 L 15.272 13.091 C 15.832 13.091 16.181 13.697 15.902 14.182 C 15.772 14.407 15.532 14.545 15.272 14.545 L 0.727 14.545 C 0.326 14.545 0 14.219 0 13.818 M 0 8 C 0 7.599 0.326 7.273 0.727 7.273 L 15.272 7.273 C 15.832 7.273 16.181 7.879 15.902 8.364 C 15.772 8.589 15.532 8.727 15.272 8.727 L 0.727 8.727 C 0.326 8.727 0 8.402 0 8 M 0 2.182 C 0 1.781 0.326 1.455 0.727 1.455 L 15.272 1.455 C 15.832 1.455 16.181 2.062 15.902 2.546 C 15.772 2.771 15.532 2.909 15.272 2.909 L 0.727 2.909 C 0.326 2.909 0 2.584 0 2.182" />
							</svg>
						</div>
						
						<div class="menu-dropdown">
							<main class="menu-content">
								<ul class="menu-buttons">
									<vue-button is="li" class="menu-button button-flat button-wide" @click="$store.dispatch('setScheme', $store.state.scheme === 'dark' ? 'light' : $store.state.scheme === 'light' ? 'system' : 'dark')">
										<svg v-if="$store.state.scheme === 'light'" class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
											<path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
										</svg>
										<svg v-else-if="$store.state.scheme === 'dark'" class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
											<path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278" />
											<path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z" />
										</svg>
										<svg v-else class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
											<path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16M1 8a7 7 0 0 0 7 7 3.5 3.5 0 1 0 0-7 3.5 3.5 0 1 1 0-7 7 7 0 0 0-7 7" />
										</svg>
										
										<p class="text">Цветовая схема</p>
										
										<span>{{ $store.state.scheme === 'dark' ? 'Тёмная' : $store.state.scheme === 'light' ? 'Светлая' : 'Системная' }}</span>
									</vue-button>
									<vue-button is="li" class="menu-button button-flat button-wide" @click="$utils.open({href: 'https://vk.com/pptbot', target: '_blank'})">
										<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 32 32">
											<path d="M 17.42 25.983 C 6.485 25.983 0.252 18.503 0 6.033 L 5.497 6.033 C 5.671 15.177 9.699 19.047 12.894 19.843 L 12.894 6.033 L 18.058 6.033 L 18.058 13.913 C 21.206 13.578 24.532 9.985 25.65 6.017 L 30.802 6.017 C 29.952 10.168 27.413 13.772 23.801 15.979 C 27.838 17.986 30.82 21.628 32 25.983 L 26.324 25.983 C 25.112 22.195 22.073 19.252 18.058 18.853 L 18.058 25.983 L 17.42 25.983 Z M 17.42 25.983" />
										</svg>
										
										<p class="text">ВКонакте</p>
										
										<span>@pptbot</span>
									</vue-button>
									<vue-button is="li" class="menu-button button-flat button-wide" @click="$utils.open({href: 'https://t.me/ppt_bot', target: '_blank'})">
										<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 32 32">
											<path d="M 12.465 20.333 L 11.793 27.504 C 11.793 27.504 11.504 29.695 13.701 27.504 C 15.899 25.305 17.996 23.614 17.996 23.614" style="" />
											<path d="M 7.876 18.194 L 0.64 15.829 C 0.64 15.829 -0.22 15.483 0.054 14.687 C 0.118 14.528 0.235 14.391 0.581 14.145 C 2.18 13.033 30.21 2.956 30.21 2.956 C 30.21 2.956 31.006 2.689 31.469 2.863 C 31.707 2.941 31.88 3.137 31.939 3.376 C 31.981 3.577 32.011 3.794 31.996 4.012 C 31.989 4.199 31.974 4.366 31.954 4.64 C 31.787 7.387 26.669 27.951 26.669 27.951 C 26.669 27.951 26.373 29.158 25.273 29.195 C 24.731 29.217 24.204 29.006 23.813 28.631 C 21.66 26.78 14.236 21.793 12.596 20.687 C 12.516 20.644 12.472 20.557 12.465 20.47 C 12.437 20.355 12.559 20.211 12.559 20.211 C 12.559 20.211 25.497 8.718 25.838 7.502 C 25.866 7.415 25.773 7.365 25.629 7.402 C 24.776 7.721 9.878 17.13 8.236 18.172 C 8.122 18.201 7.99 18.207 7.876 18.194 Z M 7.876 18.194" style="" />
										</svg>
										
										<p class="text">Telegram</p>
										
										<span>@ppt_bot</span>
									</vue-button>
								</ul>
							</main>
							
							<footer class="menu-footer">
								<p class="copyright">ППТ Бот &copy; {{ $store.state.date.getFullYear() }}</p>
							</footer>
						</div>
					</menu>
					
					<nav class="navigation">
						<ul>
							<li :class="{active: $route.name === 'Lessons'}" @click="$router.push({name: 'Lessons', query: this.$route.query}).catch(() => null)">
								<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
									<path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2M9.5 7h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5m3 0h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5M2 10.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5" />
								</svg>
								
								<p class="text">Уроки</p>
							</li>
							<li :class="{active: $route.name === 'Menu'}" @click="$router.push({name: 'Menu', query: this.$route.query}).catch(() => null)">
								<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
									<path d="M.5 6a.5.5 0 0 0-.488.608l1.652 7.434A2.5 2.5 0 0 0 4.104 16h5.792a2.5 2.5 0 0 0 2.44-1.958l.131-.59a3 3 0 0 0 1.3-5.854l.221-.99A.5.5 0 0 0 13.5 6zM13 12.5a2.01 2.01 0 0 1-.316-.025l.867-3.898A2.001 2.001 0 0 1 13 12.5" />
									<path d="m4.4.8-.003.004-.014.019a4.167 4.167 0 0 0-.204.31 2.327 2.327 0 0 0-.141.267c-.026.06-.034.092-.037.103v.004a.593.593 0 0 0 .091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3.31 3.31 0 0 1-.202.388 5.444 5.444 0 0 1-.253.382l-.018.025-.005.008-.002.002A.5.5 0 0 1 3.6 4.2l.003-.004.014-.019a4.149 4.149 0 0 0 .204-.31 2.06 2.06 0 0 0 .141-.267c.026-.06.034-.092.037-.103a.593.593 0 0 0-.09-.252A4.334 4.334 0 0 0 3.6 2.8l-.01-.012a5.099 5.099 0 0 1-.37-.543A1.53 1.53 0 0 1 3 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a5.446 5.446 0 0 1 .253-.382l.025-.035A.5.5 0 0 1 4.4.8m3 0-.003.004-.014.019a4.167 4.167 0 0 0-.204.31 2.327 2.327 0 0 0-.141.267c-.026.06-.034.092-.037.103v.004a.593.593 0 0 0 .091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3.31 3.31 0 0 1-.202.388 5.444 5.444 0 0 1-.253.382l-.018.025-.005.008-.002.002A.5.5 0 0 1 6.6 4.2l.003-.004.014-.019a4.149 4.149 0 0 0 .204-.31 2.06 2.06 0 0 0 .141-.267c.026-.06.034-.092.037-.103a.593.593 0 0 0-.09-.252A4.334 4.334 0 0 0 6.6 2.8l-.01-.012a5.099 5.099 0 0 1-.37-.543A1.53 1.53 0 0 1 6 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a5.446 5.446 0 0 1 .253-.382l.025-.035A.5.5 0 0 1 7.4.8m3 0-.003.004-.014.019a4.077 4.077 0 0 0-.204.31 2.337 2.337 0 0 0-.141.267c-.026.06-.034.092-.037.103v.004a.593.593 0 0 0 .091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3.198 3.198 0 0 1-.202.388 5.385 5.385 0 0 1-.252.382l-.019.025-.005.008-.002.002A.5.5 0 0 1 9.6 4.2l.003-.004.014-.019a4.149 4.149 0 0 0 .204-.31 2.06 2.06 0 0 0 .141-.267c.026-.06.034-.092.037-.103a.593.593 0 0 0-.09-.252A4.334 4.334 0 0 0 9.6 2.8l-.01-.012a5.099 5.099 0 0 1-.37-.543A1.53 1.53 0 0 1 9 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a5.446 5.446 0 0 1 .253-.382l.025-.035A.5.5 0 0 1 10.4.8" />
								</svg>
								
								<p class="text">Меню</p>
							</li>
							<li :class="{active: $route.name === 'Timetable'}" @click="$router.push({name: 'Timetable', query: this.$route.query}).catch(() => null)">
								<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
									<path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
								</svg>
								
								<p class="text">Звонки</p>
							</li>
							<li :class="{active: $route.name === 'Building'}" @click="$router.push({name: 'Building', query: this.$route.query}).catch(() => null)">
								<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
									<path d="M15.5 8.516a7.5 7.5 0 1 1-9.462-7.24A1 1 0 0 1 7 0h2a1 1 0 0 1 .962 1.276 7.5 7.5 0 0 1 5.538 7.24m-3.61-3.905L6.94 7.439 4.11 12.39l4.95-2.828 2.828-4.95z" />
								</svg>
								
								<p class="text">Здание</p>
							</li>
						</ul>
					</nav>
				</div>
			</header>
			
			<main class="content">
				<div v-if="$store.state.sw.updated !== undefined" class="update-banner" @click.prevent="$store.dispatch('updateServiceWorker')">
					<section class="content-section content-wrapper">
						<div class="update-banner-content">
							<p class="text">Обнаружена новая версия сайта!</p>
							
							<div class="update-button">
								<p class="text">ОБНОВИТЬ</p>
								
								<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
									<path fill-rule="evenodd" d="M 2.857 8 C 2.857 7.645 3.145 7.357 3.5 7.357 L 10.948 7.357 L 8.188 4.598 C 7.837 4.248 7.998 3.65 8.476 3.521 C 8.698 3.462 8.935 3.525 9.098 3.688 L 12.955 7.545 C 13.207 7.796 13.207 8.204 12.955 8.455 L 9.098 12.312 C 8.747 12.663 8.149 12.502 8.021 12.024 C 7.961 11.802 8.025 11.565 8.188 11.402 L 10.948 8.643 L 3.5 8.643 C 3.145 8.643 2.857 8.355 2.857 8" />
								</svg>
							</div>
						</div>
					</section>
				</div>
				
				<section v-if="$route.name !== 'Timetable' && ($store.getters.timetable.next || $store.getters.timetable.current)" class="content-section content-wrapper">
					<article class="banner" @click.prevent="$router.push({name: 'Timetable', query: $route.query}).catch(() => null)">
						<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
							<path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z" />
						</svg>
						
						<p v-if="$store.getters.timetable.next" class="text">{{ $store.getters.timetable.next.title + ' начнётся через ' + this.$utils.getDateDifference($store.getters.timetable.date, new Date($store.getters.timetable.next.start), {seconds: 'с', minutes: 'м', hours: 'ч', days: 'д'}).join(', ') }}</p>
						<p v-if="$store.getters.timetable.current" class="text">{{ $store.getters.timetable.current.title + ' закончится через ' + this.$utils.getDateDifference($store.getters.timetable.date, new Date($store.getters.timetable.current.end), {seconds: 'с', minutes: 'м', hours: 'ч', days: 'д'}).join(', ') }}</p>
					</article>
				</section>
				
				<router-view />
			</main>
		</div>
		
		<vue-image v-if="image !== null" :src="image.src" @close="$router.push({query: {...$route.query, image: undefined}})" />
	</div>
</template>

<script>
	import VueButton from '@/components/VueButton';
	
	import VueImage from '@/components/VueImage';
	
	export default {
		name: 'Main',
		
		components: {VueButton, VueImage},
		
		data() {
			return {
				menu: {
					active: false
				}
			}
		},
		
		computed: {
			image() {
				const file = this.$store.state.files.find(({ hash }) => hash === this.$route.query.image);
				
				return file ? {src: file.base64 || file.url, ...file} : null;
			}
		},
		
		methods: {
			onMouseDown(e) {
				if(e.target.closest('.app > .header .menu') !== this.$refs.menu) this.menu.active = false;
			},
			
			onTouchStart(e) {
				if(e.target.closest('.app > .header .menu') !== this.$refs.menu) this.menu.active = false;
			},
			
			onClick(e) {
				if(e.target.closest('.app > .header .menu') !== this.$refs.menu) this.menu.active = false;
			}
		},
		
		mounted() {
			window.addEventListener('mousedown', this.onMouseDown, {passive: true});
			window.addEventListener('touchstart', this.onTouchStart, {passive: true});
			
			window.addEventListener('click', this.onClick, {passive: true});
		},
		
		beforeUnmount() {
			window.removeEventListener('mousedown', this.onMouseDown);
			window.removeEventListener('touchstart', this.onTouchStart);
			
			window.removeEventListener('click', this.onClick);
		}
	}
</script>

<style>
	a {color: unset; text-decoration: unset}
	p, h1, h2, h3, h4, h5, h6 {margin-block-start: unset; margin-block-end: unset}
	img {user-select: none; -webkit-user-select: none; -webkit-user-drag: none}
	menu, ul, ol {list-style-type: none; margin-block-start: unset; margin-block-end: unset; padding-inline-start: unset}
	
	.icon:is(svg), .icon svg {pointer-events: none}
	
	h1 {font-size: 1.5rem}
	h2 {font-size: 1.30rem}
	h3 {font-size: 1.20rem}
	h4 {font-size: 1rem}
	h5 {font-size: 0.80rem}
	h6 {font-size: 0.70rem}
	
	*, ::before, ::after {
		box-sizing: border-box;
		-webkit-box-sizing: border-box
	}
	
	html, body, input, textarea, button {
		font-family: var(--font-family);
		font-size: var(--font-size);
		font-weight: var(--font-weight);
	}
	
	html, body {
		width: 100%;
		height: 100%;
	}
	
	body {
		margin: 0;
		
		overflow: auto;
	}
	
	.root {
		width: 100%;
		height: 100%;
		
		background-color: var(--primary-background-color);
		
		color: var(--primary-text-color);
		
		transition: background-color, color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.app {
		width: 100%;
		height: 100%;
		
		position: relative;
		
		display: flex;
		
		flex-direction: column;
	}
	
	.app > .header {
		width: 100%;
		height: 60px;
		
		flex-shrink: 0;
		
		z-index: 100;
		
		background-color: var(--secondary-background-color);
		
		transition: background-color, box-shadow;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.app > .header > .wrapper {
		display: flex;
		
		align-items: center;
		
		margin: 0 auto;
		
		width: 1600px;
		height: 100%;
		
		max-width: 100%;
		
		transition: width;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.app > .header .menu {
		position: relative;
		
		flex-shrink: 0;
		
		height: 100%;
	}
	
	.app > .header .menu > .menu-toggle {
		height: 100%;
		
		cursor: pointer;
		
		display: flex;
		
		justify-content: center;
		align-items: center;
		
		position: relative;
		
		min-width: 60px;
		
		color: var(--secondary-text-color);
		
		transition: color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.app > .header .menu > .menu-toggle:hover,
	.app > .header .menu.active > .menu-toggle {
		color: var(--accent-color);
	}
	
	.app > .header .menu > .menu-dropdown {
		position: absolute;
		
		width: 360px;
		
		max-height: calc(100vh - 60px - 10px * 2);
		max-width: calc(100vw - 10px * 2);
		
		border-radius: 6px;
		
		border: 1px solid var(--secondary-border-color);
		
		background-color: var(--secondary-background-color);
		
		display: flex;
		
		flex-direction: column;
		
		overflow: hidden;
		
		transform: translateY(0px);
		
		opacity: 0;
		visibility: hidden;
		
		pointer-events: none;
		
		transition: background-color, border-color, transform, opacity, visibility;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.app > .header .menu.active > .menu-dropdown {
		opacity: 1;
		visibility: visible;
		
		pointer-events: auto;
	}
	
	.app > .header .menu > .menu-dropdown > .menu-content {
		overflow: hidden auto;
		
		scrollbar-width: thin;
		scrollbar-color: var(--accent-color) transparent;
		
		display: flex;
	}
	
	.app > .header .menu > .menu-dropdown > .menu-content .menu-buttons {
		padding: 6px;
		
		flex-grow: 1;
	}
	
	.app > .header .menu > .menu-dropdown > .menu-footer {
		text-align: center;
		
		padding: 10px;
	}
	
	.app > .header .menu > .menu-dropdown > .menu-footer > .copyright {
		font-size: .85rem;
		font-weight: bold;
	}
	
	.app > .header .navigation {
		position: relative;
		
		height: 60px;
		
		overflow: hidden;
		
		flex-grow: 1;
		
		display: flex;
		
		overflow: auto hidden;
		
		scrollbar-width: none;
	}
	
	.app > .header .navigation::-webkit-scrollbar {
		display: none !important;
	}
	
	.app > .header .navigation ul {
		height: 100%;
		
		display: inline-flex;
		
		align-items: center;
		justify-content: flex-end;
		
		flex-grow: 1;
	}
	
	.app > .header .navigation ul > li {
		display: inline-flex;
		
		align-items: center;
		justify-content: center;
		
		gap: 6px 12px;
		
		padding: 0 12px;
		
		height: 100%;
		
		min-width: 60px;
		
		position: relative;
		
		flex-shrink: 0;
		
		cursor: pointer;
		
		user-select: none;
		-webkit-user-select: none;
		
		position: relative;
		
		color: var(--secondary-text-color);
		
		transition: background-color, color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.app > .header .navigation ul > li:hover,
	.app > .header .navigation ul > li.active {
		color: var(--primary-text-color);
	}
	
	.app > .header .navigation ul > li::before {
		content: '';
		
		position: absolute;
		
		left: 0;
		
		width: 100%;
		height: 2px;
		
		transform: scaleX(0);
		
		transition: background-color, transform;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.app > .header .navigation ul > li:hover::before,
	.app > .header .navigation ul > li.active::before {
		transform: scaleX(1);
	}
	
	.app > .header .navigation ul > li:hover::before {
		background-color: var(--primary-text-color);
	}
	
	.app > .header .navigation ul > li.active::before {
		background-color: var(--accent-color);
	}
	
	.app > .header .navigation ul > li > .text {
		font-weight: bold;
		
		text-align: center;
	}
	
	.app > .content {
		flex-grow: 1;
		
		overflow: hidden auto;
		
		scrollbar-width: thin;
		scrollbar-color: var(--accent-color) transparent;
	}
	
	.app > .content .content-wrapper {
		margin: 0 auto;
		
		width: 1600px;
		
		max-width: 100%;
		
		transition: width;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.app > .content .content-section {
		padding: 20px 10px;
		
		display: flex;
		
		flex-direction: column;
		
		gap: 20px;
	}
	
	.app > .content .content-section + .content-section {
		padding-top: 0
	}
	
	.app > .content .content-section > .section-header {
		display: flex;
		
		justify-content: flex-end;
		align-items: center;
		
		flex-wrap: wrap;
		
		gap: 15px;
	}
	
	.app > .content .content-section > .section-header > .text {
		flex-grow: 1;
		
		font-weight: bold;
		
		word-break: break-word;
	}
	
	.app > .content .content-section > .section-header > .buttons {
		display: flex;
		
		flex-wrap: wrap;
		
		gap: 5px;
	}
	
	.app > .content .content-section > .section-header > .buttons > .button {
		flex-grow: 1;
	}
	
	@media (max-width: 767.9px) {
		.app > .header {
			box-shadow: inset 0 1px 0 0 var(--secondary-border-color);
			
			order: 1
		}
		
		.app > .header .menu > .menu-dropdown {
			left: 10px;
			bottom: 100%;
		}
		
		.app > .header .menu.active > .menu-dropdown {
			transform: translateY(-10px);
		}
		
		.app > .header .navigation ul > li {
			flex-grow: 1;
			
			flex-direction: column;
		}
		
		.app > .header .navigation ul > li::before {
			top: 0;
			
			transform-origin: top;
		}
		
		.app > .header .navigation ul > li > .text {
			font-size: .7rem;
		}
	}
	
	@media (min-width: 768px) {
		.app > .header {
			box-shadow: inset 0 -1px 0 0 var(--secondary-border-color);
			
			order: 0
		}
		
		.app > .header > .wrapper {
			padding: 0 10px;
		}
		
		.app > .header .menu > .menu-dropdown {
			left: 0;
			top: 100%;
		}
		
		.app > .header .menu.active > .menu-dropdown {
			transform: translateY(10px);
		}
		
		.app > .header .navigation ul > li::before {
			bottom: 0;
			
			transform-origin: bottom;
		}
		
		.app > .header .navigation ul > li > .text {
			flex-grow: 1;
		}
	}
	
	.banner {
		cursor: pointer;
		
		display: flex;
		
		align-items: center;
		
		justify-content: center;
		
		flex-wrap: wrap;
		
		text-align: center;
		
		padding: 20px;
		
		gap: 20px;
		
		background-color: var(--secondary-background-color);
		
		border: 1px solid var(--secondary-border-color);
		
		color: var(--primary-text-color);
		
		border-radius: 6px;
		
		transition: background-color, border-color, color, opacity;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.banner:hover {opacity: .8}
	.banner:active {opacity: .6}
	
	.banner > .text {
		flex-grow: 1;
	}
	
	.update-banner {
		cursor: pointer;
		
		position: sticky;
		
		top: 0;
		
		background-color: var(--accent-color);
		
		color: var(--accent-contrast-color);
		
		user-select: none;
		
		-webkit-user-drag: none;
		
		z-index: 99;
		
		transition: color, background-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.update-banner:hover {opacity: .8}
	.update-banner:active {opacity: .6}
	
	.update-banner .update-banner-content {
		display: flex;
		
		align-items: center;
		
		column-gap: 20px;
	}
	
	.update-banner .update-banner-content > .text {
		flex-grow: 1;
		
		text-transform: uppercase;
	}
	
	.update-banner .update-banner-content > .update-button {
		flex-shrink: 0;
	}
	
	.update-banner .update-button {
		display: flex;
		
		align-items: center;
		
		column-gap: 10px;
		
		font-weight: bold;
	}
	
	.update-banner .update-button > .icon {
		transition: transform 150ms linear;
	}
	
	.update-banner:hover .update-button > .icon {
		transform: translateX(5px);
	}
</style>
<template>
	<div class="image-carousel" >
		<div class="carousel-items" ref="items">
			<div v-for="item, i in images" :key="i" class="carousel-item" @click="$emit('preview', item)">
				<img class="carousel-image" :src="item.src" :alt="'Carousel image №' + (i + 1)">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'VueCarousel',
		
		emits: ['preview'],
		
		props: {
			images: {type: Array[Object], required: true}
		},
		
		methods: {
			scrollLeft() {
				this.$refs.items.scrollBy({left: -this.$refs.items.clientWidth, behavior: 'smooth'});
			},
			
			scrollRight() {
				this.$refs.items.scrollBy({left: this.$refs.items.clientWidth, behavior: 'smooth'});
			}
			
		}
	}
</script>

<style>
	.image-carousel {
		position: relative;
		
		background-color: var(--secondary-background-color);
		
		border: 1px solid var(--secondary-border-color);
		
		display: flex;
		
		border-radius: 6px;
		
		min-height: 400px;
		max-height: 1024px;
		
		overflow: hidden;
		
		resize: vertical;
		
		transition: background-color, border-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.image-carousel > .carousel-items {
		display: inline-flex;
		
		column-gap: 10px;
		
		height: 100%;
		
		padding: 10px;
		
		flex-grow: 1;
		
		overflow: auto hidden;
		
		scrollbar-width: thin;
		scrollbar-color: var(--accent-color) transparent;
	}
	
	.image-carousel > .carousel-items > .carousel-item {
		flex-shrink: 0;
		
		overflow: hidden;
		
		height: 100%;
		
		border-radius: 4px;
		
		cursor: pointer;
		
		transition: border-color;
		transition-timing-function: var(--transition-timing-function);
		transition-duration: var(--transition-duration);
	}
	
	.image-carousel > .carousel-items > .carousel-item > .carousel-image {
		height: 100%;
		
		vertical-align: middle;
		
		pointer-events: none;
	}
</style>